// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-guide-js": () => import("./../src/pages/guide.js" /* webpackChunkName: "component---src-pages-guide-js" */),
  "component---src-pages-help-js": () => import("./../src/pages/help.js" /* webpackChunkName: "component---src-pages-help-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-observe-js": () => import("./../src/pages/observe.js" /* webpackChunkName: "component---src-pages-observe-js" */),
  "component---src-pages-training-js": () => import("./../src/pages/training.js" /* webpackChunkName: "component---src-pages-training-js" */)
}

